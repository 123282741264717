import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import AccessForm from 'components/AccessForm';
import BackButton from 'components/BackButton';
import CollectionList from 'features/collection/CollectionList';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import TitleDescription from 'components/TitleDescription';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import api from 'utils/api';
import FolderSurveys from 'features/survey/SurveyList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';

const OopsBoardDetailed = () => {
  const {
    isOpen: showDeleteBoardModal,
    onOpen: openDeleteBoardModal,
    onClose: closeDeleteBoardModal
  } = useDisclosure();

  const {
    isOpen: showUpdateBoardModal,
    onOpen: openUpdateBoardModal,
    onClose: closeUpdateBoardModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { boardId } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    status,
    data: board,
    isSuccess
  } = useQuery(['board', boardId], async () => {
    const { data } = await api.get(`/boards/${boardId}`);
    return data;
  });

  const deleteBoardMutation = useMutation(
    () => api.delete(`/boards/${boardId}`),
    {
      onSuccess: () => {
        closeDeleteBoardModal();
        toast({
          title: 'Board was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        navigate(-1);
      }
    }
  );

  const updateBoardMutation = useMutation(
    board => api.patch(`/boards/${boardId}`, board, { timeout: 0 }),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['board', boardId], data);
      }
    }
  );

  const createFollowMutation = useMutation(
    () => api.post(`/boards/${boardId}/follow`),

    {
      onSuccess: ({ data: board }) => {
        queryClient.setQueryData(['board', boardId], board);
      }
    }
  );

  const deleteFollowMutation = useMutation(
    () => api.delete(`/boards/${boardId}/follow`),
    {
      onSuccess: () => {
        queryClient.setQueryData(['board', boardId], {
          ...board,
          followed_at: null
        });
      }
    }
  );

  const handleOnUpdateBoard = async board => {
    const payload = new FormData();
    if (!board.image || board.image instanceof File) {
      payload.append('image', board.image ? board.image : '');
    }
    if (!board.video || board.video instanceof File) {
      payload.append('video', board.video ? board.video : '');
    }
    payload.append('unsplash', board.unsplash ? board.unsplash : '');
    payload.append('title', board.title);
    payload.append('description', board.description ? board.description : '');
    payload.append('access', board.access.id);
    try {
      await updateBoardMutation.mutateAsync(payload);
      await updateBoardMutation.mutateAsync({
        domains: board.domains.map(domain => domain.id),
        groups: board.groups.map(group => group.id),
        members: board.members.map(member => member.id)
      });
      closeUpdateBoardModal();
      toast({
        title: 'Board was successfully updated.',
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
    } catch (e) {
      toast({
        title: 'Could not update board. Please try again later.',
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    }
  };

  const handleOnClickDelete = async () => {
    await deleteBoardMutation.mutateAsync();
  };

  const steps = [
    { title: '1 Oktober 2024', description: 'Projektet startar' },
    {
      title: '10 November 2024',
      description: 'Första dialog startar – delta med dina idéer'
    },
    {
      title: '20 Dec 2024',
      description: 'Slutsatserna från den första dialogen presenteras'
    },
    {
      title: '10 Jan 2025',
      description: 'Enkät om hållbara utmaningar skickas ut'
    },
    {
      title: '25 Jan 2025',
      description: 'Resultatet från enkäten presenteras'
    },
    {
      title: '18 Feb 2025',
      description:
        'Projektet avslutas och rapport med förslag på åtgärder presenteras'
    }
  ];

  const handleOnClickFollow = async () => {
    if (board.followed_at) {
      await deleteFollowMutation.mutateAsync();
    } else {
      await createFollowMutation.mutateAsync();
    }
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.board').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <BackButton />
            <CoverMedia object={board} height="280px" />
            <TitleDescription
              title={board.title}
              description={board.description}
              alignItemsHeadingButton="center"
              button={
                true && (
                  <HStack spacing={4}>
                    <Button variant="outline" onClick={handleOnClickFollow}>
                      <HStack>
                        <Text>
                          {board.followed_at
                            ? t('common.following')
                            : t('common.follow')}
                        </Text>
                        <Text color={board.followed_at && 'orange.400'}>
                          <FontAwesomeIcon
                            icon={board.followed_at ? faStarSolid : faStar}
                          />
                        </Text>
                      </HStack>
                    </Button>
                    <ActionMenu>
                      <EditMenuItem onClick={openUpdateBoardModal} />
                      <DeleteMenuItem onClick={openDeleteBoardModal} />
                    </ActionMenu>
                  </HStack>
                )
              }
            />
            <Divider />
            <SimpleGrid templateColumns="1fr 25%" spacing={8}>
              <Stack spacing={12}>
                <AspectRatio ratio={16 / 9}>
                  <iframe
                    title="YouTube Video"
                    allowfullscreen=""
                    frameborder="0"
                    src="https://www.youtube.com/embed/Ov2bVPUNsfM"
                    style={{ borderRadius: '0.5rem' }}
                  />
                </AspectRatio>
                <Stack spacing={8}>
                  <Heading fontSize="xl">{t('common.collections')}</Heading>
                  <CollectionList boardId={boardId} />
                </Stack>
                <Stack spacing={8}>
                  <Heading fontSize="xl">{t('common.surveys')}</Heading>
                  <FolderSurveys folderId={105} />
                </Stack>
              </Stack>
              <Stepper index={0} orientation="vertical" height="600px" gap="0">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box>
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            </SimpleGrid>
            <ModalWrapper
              title={t('common.board')}
              isOpen={showUpdateBoardModal}
              onClose={closeUpdateBoardModal}
            >
              <AccessForm
                defaultValues={board}
                isOpen={showUpdateBoardModal}
                onSubmit={handleOnUpdateBoard}
                showImageSelect
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteBoardModal}
              onClose={closeDeleteBoardModal}
              onDelete={handleOnClickDelete}
            />
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default OopsBoardDetailed;
