import { Flex, HStack, Heading, Show, Spacer, Stack } from '@chakra-ui/react';
const HeadingButton = ({
  title,
  button,
  fontSize = '2xl',
  alignItems = 'flex-start'
}) => {
  return (
    <HStack spacing={4} alignItems={alignItems}>
      <Stack spacing={4} width="full">
        <Heading noOfLines={99} wordBreak="break-word" fontSize={fontSize}>
          {title}
        </Heading>
        <Show below="lg">
          <Flex alignItems="center" justifyContent="space-between">
            {button}
          </Flex>
        </Show>
      </Stack>
      <Spacer />
      <Show above="lg">
        <HStack spacing={4}>{button}</HStack>
      </Show>
    </HStack>
  );
};

export default HeadingButton;
