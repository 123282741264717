import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Switch
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import CharacterCounterInput from 'components/CharacterCounterInput';
import ImageSelect from 'components/ImageSelect';

const SurveyFieldForm = ({
  defaultValues,
  onSubmit,
  addDefaultOptions = false
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const {
    fields: options,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'options'
  });

  useEffect(() => {
    if (addDefaultOptions) {
      append([{ option: '' }, { option: '' }], { shouldFocus: false });
    }
  }, [append, addDefaultOptions]);

  const maxLength = 200;

  const values = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <ImageSelect
          object={values}
          setValue={setValue}
          uploadVideo
          isDiscrete
        />
        <FormControl id="field">
          <FormLabel>{t('common.title')}</FormLabel>
          <CharacterCounterInput
            useControllerProps={{
              name: 'field',
              control: control,
              rules: {
                required: true,
                maxLength: {
                  value: maxLength,
                  message: t('common.character_limit_error', {
                    limit: maxLength
                  })
                }
              },
              defaultValue: defaultValues?.field
            }}
            placeholder={t('placeholder.enter_field')}
            autoFocus
          />
        </FormControl>
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormLabel htmlFor="required" mb="0">
            {t('common.required')}
          </FormLabel>
          <Switch
            id="required"
            {...register('required')}
            defaultChecked={defaultValues?.required}
          />
        </FormControl>
        {addDefaultOptions && (
          <>
            <FormControl id="options">
              <FormLabel>{t('common.options')}</FormLabel>
              <Stack spacing={4}>
                {options.map((field, index) => (
                  <HStack key={field.id} alignItems="flex-start">
                    <CharacterCounterInput
                      useControllerProps={{
                        name: `options.${index}.option`,
                        control: control,
                        rules: {
                          required: true,
                          maxLength: {
                            value: maxLength,
                            message: t('common.character_limit_error', {
                              limit: maxLength
                            })
                          }
                        },
                        defaultValue: defaultValues?.field
                      }}
                      placeholder={t('placeholder.enter_field')}
                      autoFocus
                    />
                    <Button
                      variant="outline"
                      isDisabled={index < 2}
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </HStack>
                ))}
              </Stack>
            </FormControl>
            <Button
              alignSelf="flex-start"
              variant="link"
              onClick={() => append({ option: '' })}
            >
              {t('workflow.add_option')}
            </Button>
          </>
        )}

        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default SurveyFieldForm;
