import { AsyncSelect, Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Stack, Text, chakra } from '@chakra-ui/react';
import { useState } from 'react';
import api from 'utils/api';

const fetchUsers = async query => {
  const { data } = await api.get(`/users?search=${query}`);
  return data;
};

const CollectionCardFilter = ({
  collection,
  filter,
  setFilter,
  omittedFilters,
  menuPortalTarget = document.body,
  columns = [1, 2, 3, 4]
}) => {
  const { t } = useTranslation();
  const [selectedAssignee, setSelectedAssignee] = useState(
    filter?.assignee ? filter.assignee : null
  );

  let workflowSteps = [];

  if (collection.workflow) {
    workflowSteps = collection.workflow.steps.map(({ name, id }) => ({
      label: name,
      value: id
    }));
  }

  const stepOptions = [
    { label: t('common.all_steps'), value: 'ALL' },
    { label: t('common.inbox'), value: 'INBOX' },
    ...workflowSteps
  ];

  const orderingOptions = [
    { label: t('common.newest_first'), value: '-created_at' },
    { label: t('common.oldest_first'), value: 'created_at' }
  ];

  const StyledAsyncSelect = chakra(AsyncSelect);

  return (
    <SimpleGrid columns={columns} spacing={4}>
      <Stack>
        <Text variant="muted">{t('button.sort_by')}</Text>
        <Select
          value={
            filter?.ordering
              ? orderingOptions.find(
                  ordering => ordering.value === filter.ordering
                )
              : orderingOptions[0]
          }
          defaultValue={orderingOptions[0]}
          menuPortalTarget={menuPortalTarget}
          options={orderingOptions}
          onChange={option => {
            setFilter({ ...filter, ordering: option.value });
            return option;
          }}
          useBasicStyles
        />
      </Stack>
      {!omittedFilters?.step && collection.workflow?.steps.length > 0 && (
        <Stack>
          <Text variant="muted">{t('common.step')}</Text>
          <Select
            defaultValue={stepOptions[0]}
            menuPortalTarget={menuPortalTarget}
            options={stepOptions}
            onChange={option => {
              const value = option.value;
              if (value === 'ALL') {
                setFilter({
                  ...filter,
                  step: null,
                  step__isnull: null
                });
              } else if (value === 'INBOX') {
                setFilter({
                  ...filter,
                  step: null,
                  step__isnull: true
                });
              } else {
                setFilter({
                  ...filter,
                  step: value,
                  step__isnull: null
                });
              }
            }}
            useBasicStyles
          />
        </Stack>
      )}
      <Stack>
        <Text variant="muted">{t('common.assigned_to')}</Text>
        <StyledAsyncSelect
          value={selectedAssignee}
          loadOptions={fetchUsers}
          placeholder={t('placeholder.search_members')}
          getOptionLabel={member => member.full_name}
          getOptionValue={member => member.id}
          onChange={value => {
            setSelectedAssignee(value);
            setFilter({ ...filter, assignee: value?.id ? value.id : value });
          }}
          isClearable
          useBasicStyles
        />
      </Stack>
    </SimpleGrid>
  );
};

export default CollectionCardFilter;
