import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';
import { Select } from 'chakra-react-select';

const OptionForm = ({ defaultValues, isOpen, onSubmit, fields }) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues, isOpen]);

  const maxLength = 200;
  const { option } = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="option">
          <FormLabel>{t('common.option')}</FormLabel>
          <CharacterCounterInput
            useControllerProps={{
              name: 'option',
              control: control,
              rules: {
                maxLength: {
                  value: maxLength,
                  message: t('common.character_limit_error', {
                    limit: maxLength
                  })
                }
              },
              defaultValue: defaultValues?.option ? defaultValues.option : ''
            }}
          />
        </FormControl>
        {fields && fields.length > 0 && (
          <FormControl>
            <FormLabel>{t('common.jump_to')}</FormLabel>
            <Select
              options={fields.map(field => ({
                id: field.id,
                field: field.field
              }))}
              getOptionLabel={f => f.field}
              getOptionValue={f => f.id}
              useBasicStyles
            />
          </FormControl>
        )}
        <Flex justifyContent="flex-end">
          <Button
            isDisabled={option?.length < 1 || !isValid}
            type="submit"
            colorScheme="teal"
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default OptionForm;
