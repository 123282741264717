import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Show,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import PoweredByHives from 'components/PoweredByHives';
import { useConfetti } from 'providers/ConfettiProvider';
import api from 'utils/api';
import SurveyPreviewFields from './SurveyPreviewFields';
import TitleDescription from 'components/TitleDescription';

const SurveyPreview = () => {
  const [fieldIndex, setFieldIndex] = useState(0);

  const { fire } = useConfetti();
  const { slug, code } = useParams();

  const { t } = useTranslation();

  const [introduction, setIntroduction] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);

  const queryClient = useQueryClient();

  const {
    status: surveyStatus,
    data: survey,
    isSuccess: surveyIsSuccess
  } = useQuery(['survey', 'preview', code], async () => {
    const { data } = await api.get(`/surveys/previews/${code}`);
    return data;
  });

  const handleOnClickPrevious = () => {
    if (fieldIndex > 0) {
      setFieldIndex(fieldIndex - 1);
      setMissingRequired(false);
    }
  };

  const handleOnClickNext = () => {
    if (!missingRequired && fieldIndex < survey.fields.length - 1) {
      setFieldIndex(fieldIndex + 1);
      setMissingRequired(false);
    }
  };

  useEffect(() => {
    if (completed) {
      fire();
    }
  }, [completed, fire]);

  const hive = queryClient.getQueryData(['hive', slug]);

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  const HeaderNavigation = ({ ...props }) => (
    <HStack {...props}>
      <Link to={`/${slug}`}>
        <Image src={logo} height={8} />
      </Link>
      <Spacer />
      <Stack>
        <Text textAlign="right" variant="light">
          {t('survey.no_responses_saved')}
        </Text>
      </Stack>
    </HStack>
  );

  const FooterNavigation = ({ ...props }) => (
    <HStack {...props} pointerEvents="none">
      <Flex pointerEvents="auto">
        <PoweredByHives />
      </Flex>
      <Spacer />
      {!introduction && !completed && (
        <>
          <Text>{`${fieldIndex + 1} / ${survey?.fields.length}`}</Text>
          <HStack pointerEvents="auto">
            <Button
              variant="outline"
              isDisabled={fieldIndex === 0}
              onClick={handleOnClickPrevious}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
            <Button
              variant="outline"
              isDisabled={
                missingRequired || fieldIndex === survey?.fields.length - 1
              }
              onClick={handleOnClickNext}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </HStack>
        </>
      )}
    </HStack>
  );

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Show above="lg">
        <HeaderNavigation
          position="absolute"
          minHeight="40px"
          top={8}
          left={8}
          right={8}
        />
      </Show>
      <Container maxW="container.xs" marginY={8}>
        <LoadingWrapper statuses={[surveyStatus]}>
          {surveyIsSuccess && (
            <Stack spacing={8}>
              <Show below="lg">
                <HeaderNavigation />
              </Show>
              {introduction ? (
                <Stack spacing={8}>
                  <CoverMedia object={survey} height="200px" hideIfEmpty />
                  <TitleDescription
                    title={survey.title}
                    description={survey.description}
                    noOfLines={5}
                  />
                  <Button
                    colorScheme="teal"
                    alignSelf="flex-end"
                    onClick={() => {
                      setIntroduction(false);
                    }}
                    isDisabled={survey.fields.length === 0}
                  >
                    {t('button.start')}
                  </Button>
                </Stack>
              ) : completed ? (
                <Stack spacing={8}>
                  <Heading fontSize="3xl">
                    {`${t('survey.completion.thank_you_title')} 🎉`}
                  </Heading>
                  <Text>{t('survey.completion.thank_you_description')}</Text>
                </Stack>
              ) : (
                <Stack spacing={8}>
                  <SurveyPreviewFields
                    survey={survey}
                    fieldIndex={fieldIndex}
                    onNext={handleOnClickNext}
                    onDone={() => setCompleted(true)}
                    missingRequired={missingRequired}
                    setMissingRequired={setMissingRequired}
                  />
                </Stack>
              )}
              <Show below="lg">
                <FooterNavigation />
              </Show>
            </Stack>
          )}
          <Show above="lg">
            <FooterNavigation
              position="absolute"
              minHeight="40px"
              bottom={8}
              left={8}
              right={8}
              spacing={4}
            />
          </Show>
        </LoadingWrapper>
      </Container>
    </Flex>
  );
};

export default SurveyPreview;
