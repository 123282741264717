import { Stack, Text } from '@chakra-ui/react';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const SubfolderListItem = ({ slug, folder, breadcrumb }) => (
  <Stack
    as={Link}
    to={`/${slug}/folder/${folder.id}`}
    alignItems="flex-start"
    flexDirection="column"
    userSelect="none"
    cursor="pointer"
    minHeight="120px"
    rounded="lg"
    borderWidth={1}
    padding={6}
  >
    <Text fontSize="4xl" color="blue.100">
      <FontAwesomeIcon icon={faFolder} />
    </Text>
    <Stack spacing={0}>
      <Text>{folder.name}</Text>
      {breadcrumb && (
        <Text fontSize="xs" opacity={0.6}>
          {breadcrumb}
        </Text>
      )}
    </Stack>
  </Stack>
);

export default SubfolderListItem;
