import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AspectRatio,
  Button,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import CoverMedia from './CoverMedia';
import UnsplashModal from './modals/UnsplashModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-duotone-svg-icons';
import {
  faEllipsisVertical,
  faSearch,
  faPlayCircle
} from '@fortawesome/pro-regular-svg-icons';
import { findMedia } from 'utils/media';
import { ActionMenu, CustomMenuItem, DeleteMenuItem } from './ActionMenu';
import IconTooltip from './tooltips/IconTooltip';

const ImageSelect = ({
  object,
  preloadImage = false,
  setValue,
  uploadVideo = false,
  isDiscrete = false
}) => {
  const imageRef = useRef();
  const videoRef = useRef();
  const [showUnsplashModal, setShowUnsplashModal] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();

  // Fetch random Unsplash image on preload
  useEffect(() => {
    if (preloadImage) {
      const fetchImage = async () => {
        try {
          const UNSPLASH_COLLECTION_ID = 3330448;
          const { data } = await axios.get(
            `https://api.unsplash.com/photos/random?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&collections=${UNSPLASH_COLLECTION_ID}`
          );
          setValue('unsplash', data.urls.regular);
        } catch (error) {
          console.error('Error fetching Unsplash image:', error);
        }
      };

      fetchImage();
    }
  }, [preloadImage, setValue]);

  // Handlers for file upload and actions
  const toggleUnsplashModal = () => setShowUnsplashModal(prev => !prev);

  const handleRemoveMedia = () => {
    setValue('image', null);
    setValue('unsplash', null);
    if (uploadVideo) setValue('video', null);
  };

  const handleUploadImage = e => {
    const image = e.target.files[0];
    setValue('image', image);
    setValue('unsplash', null);
    if (uploadVideo) setValue('video', null);
  };

  const handleUploadVideo = e => {
    const video = e.target.files[0];

    if (video.size > 1000 * 1024 * 1024) {
      toast({
        title: t('common.video_size_error'),
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
      return;
    }

    if (!video.name.match(/\.(mp4|webm)$/i)) {
      toast({
        title: t('common.video_format_error'),
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
      return;
    }

    setValue('image', null);
    setValue('unsplash', null);
    setValue('video', video);
  };

  const handleUploadUnsplash = url => {
    setValue('unsplash', url);
    setValue('image', null);
    if (uploadVideo) setValue('video', null);
  };

  return (
    <Stack position="relative">
      {!isDiscrete || findMedia(object) ? (
        <AspectRatio ratio={16 / 9}>
          <CoverMedia object={object} />
        </AspectRatio>
      ) : (
        <Menu>
          <MenuButton alignSelf="flex-start" as={Button} variant="outline">
            <HStack>
              <FontAwesomeIcon icon={faImage} />
              <Text>{t('button.add_media')}</Text>
            </HStack>
          </MenuButton>
          <MenuList>
            {uploadVideo && (
              <CustomMenuItem
                icon={faPlayCircle}
                label={t('button.upload_video')}
                onClick={() => videoRef.current.click()}
                command={
                  <IconTooltip label={t('common.video_format_disclaimer')} />
                }
              />
            )}
            <CustomMenuItem
              icon={faImage}
              label={t('button.upload_image')}
              onClick={() => imageRef.current.click()}
            />
            <CustomMenuItem
              icon={faSearch}
              label={t('button.search_image')}
              onClick={toggleUnsplashModal}
            />
          </MenuList>
        </Menu>
      )}
      {(!isDiscrete || findMedia(object)) && (
        <ActionMenu
          icon={faEllipsisVertical}
          variant="overlay"
          size="sm"
          position="absolute"
          top={2}
          right={2}
        >
          {findMedia(object) && (
            <DeleteMenuItem
              label={t('button.remove')}
              onClick={handleRemoveMedia}
            />
          )}
          {uploadVideo && (
            <CustomMenuItem
              icon={faPlayCircle}
              label={t('button.upload_video')}
              onClick={() => videoRef.current.click()}
              command={
                <IconTooltip label={t('common.video_format_disclaimer')} />
              }
            />
          )}
          <CustomMenuItem
            icon={faImage}
            label={t('button.upload_image')}
            onClick={() => imageRef.current.click()}
          />
          <CustomMenuItem
            icon={faSearch}
            label={t('button.search_image')}
            onClick={toggleUnsplashModal}
          />
        </ActionMenu>
      )}
      <Input
        type="file"
        accept="video/mp4, video/webm"
        ref={videoRef}
        onChange={handleUploadVideo}
        hidden
      />
      <Input
        type="file"
        accept="image/*"
        ref={imageRef}
        onChange={handleUploadImage}
        hidden
      />
      <UnsplashModal
        isOpen={showUnsplashModal}
        onClose={toggleUnsplashModal}
        onImage={handleUploadUnsplash}
      />
    </Stack>
  );
};

export default ImageSelect;
