import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  Heading,
  SimpleGrid,
  Stack,
  Text
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
// import ItemWrapper from 'components/ItemWrapper';
import CoverMedia from 'components/CoverMedia';
import { findMedia } from 'utils/media';

const BoardListGalleryViewItem = ({ board }) => {
  // const { colorMode } = useColorMode();
  const { slug } = useParams();

  return (
    <ChakraCard
      variant="outline"
      cursor="pointer"
      overflow="hidden"
      as={Link}
      to={`/${slug}/board/${board.id}`}
    >
      {findMedia(board) && (
        <CoverMedia object={board} height="100px" rounded={null} />
      )}
      <ChakraCardBody>
        <Stack spacing={2}>
          <Heading fontSize="lg" noOfLines={2}>
            {board.title}
          </Heading>
          {board.description && (
            <Text noOfLines={3} fontSize="sm">
              {board.description}
            </Text>
          )}
        </Stack>
      </ChakraCardBody>
      {/* <SimpleGrid width="fit-content" columns={2} spacing={2}>
        {board.collections.map(collection => (
          <Image
            maxWidth="4rem"
            aspectRatio={1}
            src={collection.image}
            fallback={
              <Center
                borderRadius="md"
                height="4rem"
                width="4rem"
                bg={colorMode === 'light' ? 'gray.200' : 'whiteAlpha.200'}
              >
                <Text
                  color={colorMode === 'light' ? 'gray.400' : 'whiteAlpha.400'}
                >
                  <FontAwesomeIcon icon={faRectangleHistory} size="1x" />
                </Text>
              </Center>
            }
          />
        ))}
      </SimpleGrid> */}
    </ChakraCard>
  );
};

const BoardListGalleryView = ({ managerId }) => {
  // const [newCollection, setNewCollection] = useState(null);
  // // const [selectedBoard, setSelectedBoard] = useState();

  // const {
  //   isOpen: showCreateCollectionModal,
  //   onOpen: openCreateCollectionModal,
  //   onClose: closeCreateCollectionModal
  // } = useDisclosure();

  // const navigate = useNavigate();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  // const { data: profile } = useQuery(['profile', slug]);
  const { t } = useTranslation();

  const {
    status,
    data: boards,
    isSuccess
  } = useQuery(['boards', { manager: managerId }, slug], async () => {
    const { data } = await api.get(
      `/boards?hive=${hive.id}` //`/boards?hive=${hive.id}&manager=${managerId}`
    );
    return data;
  });

  // const hasCreateCollectionsPermission = profile?.is_admin_or_manager
  //   ? true
  //   : false;

  // const handleOnCloseCreateCollectionModal = () => {
  //   closeCreateCollectionModal();
  //   if (newCollection) {
  //     navigate(`/${slug}/collection/${newCollection.id}?mode=preview`);
  //   }
  // };

  //treat it like albums in a music app, with thumbnails of the collections

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.boards').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <SimpleGrid columns={[1, 2, null]} spacing={4}>
            {boards.map(board => (
              <React.Fragment key={board.id}>
                {/* Mount here a sub-component from higher up in this file, which is analogous to BoardListItem and also (for now fetches, later has serialized?) its collection (or later just fetches collection thumbnails, even?) to display as thumbs */}
                <BoardListGalleryViewItem key={board.id} board={board} />
                {/* <BoardListItem
                  key={board.id}
                  board={board}
                  hasCreateCollectionsPermission={
                    hasCreateCollectionsPermission
                  }
                  onClickCreate={board => {
                    setSelectedBoard(board);
                    openCreateCollectionModal();
                  }}
                /> */}
              </React.Fragment>
            ))}
          </SimpleGrid>
        ) : null}
        {/* {selectedBoard && (
          <ModalWrapper
            size="full"
            hasSteps
            isOpen={showCreateCollectionModal}
            onOpen={openCreateCollectionModal}
            onClose={handleOnCloseCreateCollectionModal}
          >
            <CollectionCreationForm
              isOpen={showCreateCollectionModal}
              onClose={handleOnCloseCreateCollectionModal}
              onCollectionCreated={newCollection => {
                setNewCollection(newCollection);
              }}
              board={selectedBoard}
              newCollection={newCollection}
            />
          </ModalWrapper>
        )} */}
      </LoadingWrapper>
    </>
  );
};

export default BoardListGalleryView;
