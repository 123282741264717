import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  HStack,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useColorModeValue
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';

const SurveyPreviewFields = ({
  survey,
  fieldIndex,
  onDone,
  onNext,
  setMissingRequired,
  missingRequired
}) => {
  const { t } = useTranslation();

  const currentField = survey.fields[fieldIndex];

  const goToNextField = () => {
    if (fieldIndex === survey.fields.length - 1) {
      onDone();
    } else {
      onNext();
    }
  };

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if (!isValid && !missingRequired) {
      setMissingRequired(true);
    } else if (isValid && missingRequired) {
      setMissingRequired(false);
    }
  }, [setMissingRequired, isValid, missingRequired]);

  useEffect(() => {
    if (currentField) {
      reset();
    }
  }, [currentField, reset]);

  const requiredTextColor = useColorModeValue('gray.500', 'gray.200');

  return (
    <Stack spacing={4}>
      <Flex position="fixed" left={0} top={0} width="full">
        <Progress
          value={fieldIndex + 1}
          max={survey.fields.length > 0 ? survey.fields.length : 1}
          width="full"
          height="4px"
          colorScheme="teal"
        />
      </Flex>
      {currentField && (
        <Stack spacing={8}>
          <CoverMedia object={currentField} hideIfEmpty />
          <Stack spacing={4}>
            <Heading fontSize="xl">
              {`${fieldIndex + 1}. ${currentField?.field} `}
              {currentField.required && (
                <Text
                  as="span"
                  color={requiredTextColor}
                  fontSize="xs"
                  fontWeight="normal"
                  verticalAlign="middle"
                >
                  {`(${t('common.required').toLowerCase()})`}
                </Text>
              )}
            </Heading>
            {currentField.type === 'INPUT' && (
              <form
                id="survey-form"
                onSubmit={handleSubmit(goToNextField)}
                style={{ marginBottom: 0 }}
              >
                <FormControl>
                  <Textarea
                    {...register('answer', {
                      required: currentField?.required
                    })}
                  />
                </FormControl>
              </form>
            )}
            {currentField.type === 'MULTI_SELECT' && (
              <form
                id="survey-form"
                onSubmit={handleSubmit(goToNextField)}
                style={{ marginBottom: 0 }}
              >
                <FormControl>
                  <Controller
                    name="options"
                    control={control}
                    rules={{ required: currentField?.required }}
                    render={({ field: { value = [], onChange } }) => (
                      <Stack>
                        {currentField.options.map(option => (
                          <Box
                            key={option.id}
                            borderWidth={1}
                            borderRadius="lg"
                            cursor="pointer"
                            padding={4}
                            onClick={() => {
                              const updatedValue = value.includes(option.id)
                                ? value.filter(id => id !== option.id)
                                : [...value, option.id];
                              onChange(updatedValue);
                            }}
                          >
                            <HStack spacing={4}>
                              <Checkbox
                                isChecked={value.includes(option.id)}
                                pointerEvents="none"
                              />
                              <Text>{option.option}</Text>
                            </HStack>
                          </Box>
                        ))}
                      </Stack>
                    )}
                  />
                </FormControl>
              </form>
            )}
            {currentField.type === 'SINGLE_SELECT' && (
              <form
                id="survey-form"
                onSubmit={handleSubmit(goToNextField)}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  name="option"
                  control={control}
                  rules={{ required: currentField?.required }}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup value={value} onChange={onChange}>
                      <Stack spacing={4}>
                        {currentField.options.map(option => (
                          <Box
                            key={option.id}
                            borderWidth={1}
                            borderRadius="lg"
                            cursor="pointer"
                            padding={4}
                            onClick={() => onChange(option.id)}
                          >
                            <HStack spacing={4}>
                              <Radio value={option.id} pointerEvents="none" />
                              <Text>{option.option}</Text>
                            </HStack>
                          </Box>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </form>
            )}
          </Stack>
          <Flex alignItems="center" justifyContent="flex-end">
            <Button
              form="survey-form"
              type="submit"
              colorScheme="teal"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || missingRequired || !isValid}
            >
              {fieldIndex === survey.fields.length - 1
                ? t('common.done')
                : t('common.next')}
            </Button>
          </Flex>
        </Stack>
      )}
    </Stack>
  );
};

export default SurveyPreviewFields;
