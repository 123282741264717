import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Flex,
  Stack,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  Th,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import OptionForm from 'features/workflow/OptionForm';
import api from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

const OptionTable = ({ fieldId, baseUrl = '/options', fields }) => {
  const [selectedOption, setSelectedOption] = useState();

  const {
    isOpen: showCreateOptionModal,
    onOpen: openCreateOptionModal,
    onClose: closeCreateOptionModal
  } = useDisclosure();

  const {
    isOpen: showDeleteOptionModal,
    onOpen: openDeleteOptionModal,
    onClose: closeDeleteOptionModal
  } = useDisclosure();

  const {
    isOpen: showUpdateOptionModal,
    onOpen: openUpdateOptionModal,
    onClose: closeUpdateOptionModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: options,
    isSuccess
  } = useQuery(['options', fieldId], async () => {
    const { data } = await api.get(`${baseUrl}?field=${fieldId}`);
    return data;
  });

  const createOptionMutation = useMutation(
    option => api.post(baseUrl, option),
    {
      onSuccess: ({ data: option }) => {
        closeCreateOptionModal();
        toast({
          title: 'Option was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(['options', fieldId], [...options, option]);
      },
      onError: () => {
        toast({
          title: `Could not create option. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteOptionMutation = useMutation(
    optionId => api.delete(`${baseUrl}/${optionId}`),
    {
      onSuccess: () => {
        closeDeleteOptionModal();
        queryClient.setQueryData(
          ['options', fieldId],
          options.filter(s => s.id !== selectedOption.id)
        );
        toast({
          title: 'Option was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const updateOptionMutation = useMutation(
    option => api.patch(`${baseUrl}/${selectedOption.id}`, option),
    {
      onSuccess: ({ data: option }) => {
        closeUpdateOptionModal();
        toast({
          title: 'Option was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['options', fieldId],
          options.map(o => (o.id === option.id ? option : o))
        );
      },
      onError: () => {
        toast({
          title: `Could not update option. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteOptionMutation.mutateAsync(selectedOption.id);
  };

  const handleOnCreateOption = async option => {
    await createOptionMutation.mutateAsync({
      ...option,
      field: fieldId
    });
  };

  const handleOnUpdateOption = async option => {
    await updateOptionMutation.mutateAsync({
      ...option,
      field: fieldId
    });
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.options').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th pl={0}>{t('common.option')}</Th>
                <Th pr={0} textAlign="end">
                  {t('common.delete')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {options.map(option => (
                <Tr key={option.id}>
                  <Td pl={0}>
                    <Button
                      variant="link"
                      onClick={() => {
                        setSelectedOption(option);
                        openUpdateOptionModal();
                      }}
                      whiteSpace="wrap"
                      textAlign="left"
                    >
                      {option.option}
                    </Button>
                  </Td>
                  <Td pr={0} textAlign="end">
                    <Button
                      variant="outline"
                      isDisabled={options.length < 3}
                      onClick={() => {
                        setSelectedOption(option);
                        openDeleteOptionModal();
                      }}
                      whiteSpace="wrap"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent="flex-start">
            <Button variant="link" onClick={openCreateOptionModal}>
              {t('workflow.add_option')}
            </Button>
          </Flex>
          <ModalWrapper
            title={t('workflow.add_option')}
            isOpen={showCreateOptionModal}
            onClose={closeCreateOptionModal}
          >
            <OptionForm onSubmit={handleOnCreateOption} />
          </ModalWrapper>
          <ModalWrapper
            title={t('common.option')}
            isOpen={showUpdateOptionModal}
            onClose={closeUpdateOptionModal}
          >
            <OptionForm
              defaultValues={{
                option: selectedOption?.option
              }}
              fields={fields}
              isOpen={showUpdateOptionModal}
              onSubmit={handleOnUpdateOption}
            />
          </ModalWrapper>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
              { data: t('common.option').toLowerCase() }
            )}
            isOpen={showDeleteOptionModal}
            onClose={closeDeleteOptionModal}
            onDelete={handleOnClickDelete}
          />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default OptionTable;
