import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import OopsBoardDetailed from './OopsBoardDetailed';
import BoardDetailed from './BoardDetailed';

const BoardIntermediate = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  console.log(hive);

  return hive.oops_mode ? <OopsBoardDetailed /> : <BoardDetailed />;
};

export default BoardIntermediate;
