import React from 'react';
import { useController } from 'react-hook-form';
import {
  Flex,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';

const CharacterCounterInput = ({
  useControllerProps = {
    name: 'field name',
    control: null,
    rules: {
      required: false,
      maxLength: {
        value: null,
        message: ''
      }
    }
  },
  placeholder = null,
  isTextArea = false,
  ...rest
}) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController(useControllerProps);

  const maxLength = useControllerProps?.rules?.maxLength.value;
  const currentLength = value?.length ? value.length : 0;
  const exceedsLimit = currentLength > maxLength;

  return (
    <Flex width="full">
      <Stack width="full" spacing={1}>
        {isTextArea ? (
          <Textarea
            id={useControllerProps.name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            isInvalid={!!error}
            {...rest}
          />
        ) : (
          <Input
            id={useControllerProps.name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            isInvalid={!!error}
            {...rest}
          />
        )}
        <Flex width="full" justifyContent="flex-end">
          {useControllerProps?.rules?.maxLength && (
            <HStack spacing={4} width="full" justifyContent="space-between">
              {exceedsLimit && (
                <Text fontSize="xs" variant="error">
                  {useControllerProps.rules.maxLength.message}
                </Text>
              )}
              <Spacer />
              <Text fontSize="xs" variant={error ? 'error' : 'light'}>
                {`${currentLength} / ${useControllerProps.rules.maxLength.value}`}
              </Text>
            </HStack>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};

export default CharacterCounterInput;
