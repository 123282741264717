import { useTranslation } from 'react-i18next';
import { Button, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import SurveyAnswer from './SurveyAnswer';
import SurveySelection from './SurveySelection';
import SurveyFilterDrawer from './SurveyFilterDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';

const SurveyResultTab = ({
  survey,
  fields,
  fieldsStatus,
  fieldsIsSuccess,
  filter,
  setFilter
}) => {
  const { t } = useTranslation();

  const {
    isOpen: showFilterDrawer,
    onOpen: openFilterDrawer,
    onClose: closeFilterDrawer
  } = useDisclosure();

  const selectionFields = survey.fields.filter(
    field =>
      field.type === 'MULTI_SELECT' ||
      (field.type === 'SINGLE_SELECT' && field.options.length > 0)
  );

  const noOfActiveFilters = filter.length;

  return (
    <>
      <LoadingWrapper statuses={[fieldsStatus]}>
        {fieldsIsSuccess && (
          <>
            <Stack spacing={8}>
              <HStack justifyContent="flex-end" spacing={4}>
                {noOfActiveFilters > 0 && (
                  <Text>
                    {noOfActiveFilters === 1
                      ? t('common.nr_filters_active_with_count_one', {
                          nr: noOfActiveFilters
                        })
                      : t('common.nr_filters_active_with_count_several', {
                          nr: noOfActiveFilters
                        })}
                  </Text>
                )}
                <Button
                  variant="outline"
                  onClick={
                    showFilterDrawer ? closeFilterDrawer : openFilterDrawer
                  }
                >
                  <FontAwesomeIcon icon={faSliders} />
                </Button>
              </HStack>

              {fields.map(field =>
                field.type === 'MULTI_SELECT' ||
                field.type === 'SINGLE_SELECT' ? (
                  <SurveySelection key={field.id} field={field} />
                ) : (
                  <SurveyAnswer
                    key={field.id}
                    field={field}
                    options={filter.map(field => field.options).flat()}
                  />
                )
              )}
            </Stack>
          </>
        )}
      </LoadingWrapper>

      <SurveyFilterDrawer
        filter={filter}
        setFilter={setFilter}
        selectionFields={selectionFields}
        isOpen={showFilterDrawer}
        onClose={closeFilterDrawer}
      />
    </>
  );
};

export default SurveyResultTab;
