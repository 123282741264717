import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Badge,
  Card as ChakraCard,
  CardBody as ChakraCardBody,
  Heading,
  Stack
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';

const SurveyListItem = ({ survey, onClick }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  return (
    <ChakraCard
      variant="outline"
      cursor="pointer"
      direction="row"
      overflow="hidden"
      as={Link}
      to={
        onClick
          ? null
          : {
              pathname: `/${slug}/survey/${survey.id}`
            }
      }
      mb={4}
      onClick={onClick ? onClick : () => {}}
    >
      <AspectRatio width={['80px', '120px', null]} ratio={16 / 9}>
        <CoverMedia object={survey} rounded={null} />
      </AspectRatio>
      <ChakraCardBody minHeight="100px">
        <Stack spacing={4} justifyContent="space-between" height="full">
          <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
            {survey.title}
          </Heading>
          <Badge alignSelf="flex-start">{t('common.survey')}</Badge>
        </Stack>
      </ChakraCardBody>
    </ChakraCard>
  );
};

export default SurveyListItem;
