import { useTranslation } from 'react-i18next';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

const SurveyFilterDrawer = ({
  selectionFields,
  filter,
  setFilter,
  onClose,
  isOpen
}) => {
  const { t } = useTranslation();

  const handleSelectChange = (selectionFieldId, value) => {
    setFilter(prevOptions => {
      if (value.length === 0) {
        // Remove the option if value is empty
        return prevOptions.filter(o => o.field !== selectionFieldId);
      }

      const updatedOption = {
        field: selectionFieldId,
        options: value.map(({ id, option }) => ({ id, option }))
      };

      // Check if the option already exists and update it, or add a new one
      const existingOptionIndex = prevOptions.findIndex(
        o => o.field === selectionFieldId
      );

      if (existingOptionIndex !== -1) {
        const updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = updatedOption;
        return updatedOptions;
      }

      return [...prevOptions, updatedOption];
    });
  };

  const getSelectedValue = selectionFieldId => {
    const option = filter.find(o => o.field === selectionFieldId);
    return option ? option.options : [];
  };

  const noOfActiveFilters = filter.length;

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {noOfActiveFilters > 0
            ? noOfActiveFilters === 1
              ? t('common.nr_filters_active_with_count_one', {
                  nr: noOfActiveFilters
                })
              : t('common.nr_filters_active_with_count_several', {
                  nr: noOfActiveFilters
                })
            : t('common.filters')}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Stack spacing={4}>
            {selectionFields.map(({ id, field, options: fieldOptions }) => (
              <Stack key={id}>
                <Text variant="muted">{field}</Text>
                <Select
                  placeholder={t('common.select')}
                  value={getSelectedValue(id)}
                  options={fieldOptions}
                  getOptionLabel={option => option.option}
                  getOptionValue={option => option.id}
                  onChange={value => handleSelectChange(id, value)}
                  useBasicStyles
                  isClearable
                  isMulti
                />
              </Stack>
            ))}
          </Stack>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default SurveyFilterDrawer;
