import SurveyFieldTable from './SurveyFieldTable';

const SurveyBuildTab = ({ surveyId, fields, filter, survey }) => (
  <SurveyFieldTable
    surveyId={surveyId}
    fields={fields}
    survey={survey}
    filter={filter}
  />
);

export default SurveyBuildTab;
