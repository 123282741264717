import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'utils/api';
import SubfolderListItem from './SubfolderListItem';
import { SimpleGrid } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import { useTranslation } from 'react-i18next';

const FolderList = ({ managerId }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    data: folders,
    isSuccess,
    status
  } = useQuery(['folders', { managerId: managerId }], async () => {
    const { data } = await api.get(
      `/folders?managers=${managerId}&hive__slug=${slug}`
    );
    return data;
  });

  //move these to utils file
  const traverseFolders = folder => {
    let folders = [];
    if (folder.parent) {
      folders = [...traverseFolders(folder.parent), folder];
    } else {
      folders = [folder];
    }
    return folders;
  };

  const sortFolders = folders => {
    return folders.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return a.name.length - b.name.length;
    });
  };

  const formatBreadcrumbs = folders => {
    const foldersWithBreadcrumbLabels = folders
      ? folders.map(folder => {
          const hierarchy = traverseFolders(folder);
          const name = hierarchy.map(f => f.name).join(' > ');
          return { id: folder?.id, name };
        })
      : [];
    const sortedFolders = sortFolders(foldersWithBreadcrumbLabels);
    return sortedFolders;
  };

  const formattedFolders = formatBreadcrumbs(folders);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.folders').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <SimpleGrid columns={[1, 3, 4]} spacing={4}>
          {isSuccess &&
            folders.map(folder => (
              <SubfolderListItem
                key={folder.id}
                slug={slug}
                folder={folder}
                breadcrumb={
                  formattedFolders.find(f => f.id === folder.id)?.name
                }
              />
            ))}
        </SimpleGrid>
      ) : null}
    </LoadingWrapper>
  );
};

export default FolderList;
