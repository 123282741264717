import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import CollectionList from '../collection/CollectionList';
import CollectionCreationForm from '../collection/CollectionCreationForm';
import ModalWrapper from 'components/ModalWrapper';
import FolderList from 'features/folders/FolderList';
import BoardListGalleryView from 'features/board/BoardListGalleryView';
import SurveyList from 'features/survey/SurveyList';
import SurveyForm from 'features/survey/SurveyForm';
import api from 'utils/api';
import axios from 'axios';

const ManagePage = () => {
  const {
    isOpen: showCreateSurveyModal,
    onOpen: openCreateSurveyModal,
    onClose: closeCreateSurveyModal
  } = useDisclosure();

  const [newCollection, setNewCollection] = useState(null);

  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const createSurveyMutation = useMutation(
    payload => api.post('/surveys', payload),
    {
      onSuccess: ({ data: survey }) => {
        closeCreateSurveyModal();
        toast({
          title: 'Survey was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(['survey', survey.id], survey);
        navigate(`/${slug}/survey/${survey.id}`);
      },
      onError: () => {
        toast({
          title: `Could not create survey. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      navigate(`/${slug}/collection/${newCollection.id}`);
    }
  };

  const handleOnCreateSurvey = async survey => {
    const payload = new FormData();
    payload.append('hive', slug);
    payload.append('image', survey.image ? survey.image : '');
    payload.append('unsplash', survey.unsplash ? survey.unsplash : '');
    payload.append('title', survey.title);
    payload.append('description', survey.description ? survey.description : '');
    if (survey.video instanceof File) {
      try {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: survey.video.name
        });
        await axios.put(data.url, survey.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      } catch (e) {
        console.log(e);
      }
    }
    await createSurveyMutation.mutateAsync(payload);
  };

  return me ? (
    <>
      <Container maxW="container.lg" marginY={8}>
        <Stack spacing={8}>
          <Heading fontSize="3xl">{t('common.managed_by_me')}</Heading>
          <Tabs variant="fresh">
            <TabList>
              <Tab>{t('common.folders')}</Tab>
              <Tab>{t('common.collections')}</Tab>
              <Tab>{t('common.surveys')}</Tab>
              <Tab>{t('common.boards')}</Tab>
            </TabList>
            <TabPanels py={4}>
              <TabPanel>
                <FolderList managerId={me.id} />
              </TabPanel>
              <TabPanel>
                <Stack spacing={8}>
                  <Text>{t('collection.instructions')}</Text>
                  <CollectionList
                    managerId={me.id}
                    openCreateCollectionModal={openCreateCollectionModal}
                  />
                </Stack>
              </TabPanel>
              <TabPanel>
                <SurveyList
                  filter={{ manager: me.id }}
                  openCreateSurveyModal={openCreateSurveyModal}
                />
              </TabPanel>
              <TabPanel>
                <BoardListGalleryView managerId={me.id} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
      <ModalWrapper
        size="full"
        isOpen={showCreateCollectionModal}
        onClose={handleOnCloseCreateCollectionModal}
        hasSteps
      >
        <CollectionCreationForm
          isOpen={showCreateCollectionModal}
          onClose={handleOnCloseCreateCollectionModal}
          onCollectionCreated={newCollection => {
            setNewCollection(newCollection);
          }}
          newCollection={newCollection}
        />
      </ModalWrapper>
      <ModalWrapper
        size="full"
        title={t('common.survey')}
        isOpen={showCreateSurveyModal}
        onClose={closeCreateSurveyModal}
        hasSteps
      >
        <SurveyForm
          isOpen={showCreateSurveyModal}
          onSubmit={handleOnCreateSurvey}
        />
      </ModalWrapper>
    </>
  ) : null;
};

export default ManagePage;
