import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';

const latitude = 56.0465;
const longitude = 12.6945;

const customIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

const CardMap = ({ coordinates }) => {
  const startCoordinate =
    coordinates?.length > 0
      ? [coordinates[0].latitude, coordinates[0].longitude]
      : [latitude, longitude];
  return (
    <MapContainer
      center={startCoordinate}
      zoom={10}
      scrollWheelZoom={true}
      style={{ height: '400px', width: '100%', zIndex: 1 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {coordinates.map(({ id, title, latitude, longitude }) => (
        <Marker key={id} position={[latitude, longitude]} icon={customIcon}>
          <Popup>{title}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default CardMap;
