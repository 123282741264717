import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SimpleGrid, useDisclosure } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import SurveyListItem from 'features/survey/SurveyListItem';
import { hasPlan, Plans } from 'features/hive/hiveUtils';
import api from 'utils/api';
import NewItem from 'components/NewItem';
import UpgradeModal from 'components/UpgradeModal';

const SurveyList = ({ filter, openCreateSurveyModal }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);

  const {
    data: surveys,
    status,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['surveys', filter],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      if (filter?.folder) {
        params.append('folder', filter.folder);
      }
      if (filter?.manager) {
        params.append('managers', filter.manager);
      }
      params.append('hive__slug', slug);
      const { data } = await api.get(`/surveys?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  const {
    isOpen: showUpgradeModal,
    onOpen: openUpgradeModal,
    onClose: closeUpgradeModal
  } = useDisclosure();

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.surveys').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <>
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <SimpleGrid columns={[1, null, 2]} spacing={6}>
              {profile?.is_admin_or_manager && openCreateSurveyModal && (
                <NewItem
                  text={t('button.new_survey')}
                  onClick={() => {
                    const hive = queryClient.getQueryData(['hive', slug]);
                    if (hive) {
                      if (
                        hasPlan(hive?.plan, Plans.Freemium) &&
                        hive.metrics.collections >= 2
                      ) {
                        openUpgradeModal();
                      } else {
                        openCreateSurveyModal();
                      }
                    }
                  }}
                />
              )}
              {surveys.pages.map(page =>
                page.results.map(survey => (
                  <SurveyListItem key={survey.id} survey={survey} />
                ))
              )}
            </SimpleGrid>
            {isFetching && <LoadingIndicator />}
          </InfiniteScrollHelper>
          <UpgradeModal isOpen={showUpgradeModal} onClose={closeUpgradeModal} />
        </>
      )}
    </LoadingWrapper>
  );
};

export default SurveyList;
