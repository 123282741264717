import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import Description from 'components/Description';
import EmptyState from 'components/EmptyState';
import HeadingButton from 'components/HeadingButton';
import CollectionListItem from 'features/collection/CollectionListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const BoardListItem = ({
  board,
  hasCreateCollectionsPermission,
  onClickCreate
}) => {
  const {
    isOpen: showAccessModal,
    onOpen: openAccessModal,
    onClose: closeAccessModal
  } = useDisclosure();

  const { slug } = useParams();
  const { t } = useTranslation();

  const BoardAccessText = () => {
    const { t } = useTranslation();

    const getAccessLabelLong = () => {
      let label = '';
      let list = [];
      if (board?.access === 'TEAM') {
        label = t('board.visible_to_all_members_of_this_hive');
      }
      if (board?.access === 'DOMAINS') {
        label = `${t('board.visible_to_the_following_domains')}:`;
        list = board?.domains.map(domain => domain.domain);
      }
      if (board?.access === 'GROUPS') {
        label = `${t('board.visible_to_the_following_groups')}:`;
        list = board?.groups.map(member => member.name);
      }
      if (board?.access === 'MEMBERS') {
        label = `${t('board.visible_to_the_following_members')}:`;
        list = board?.members.map(member => member.full_name);
      }
      return (
        <>
          <Text>{`${label}`}</Text>
          {list.length > 0 && (
            <Box ml={4} pb={2}>
              <ul>
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>
          )}
        </>
      );
    };

    return (
      <Stack spacing={2}>
        <Text>{getAccessLabelLong()}</Text>
      </Stack>
    );
  };

  return (
    <Stack spacing={6}>
      <Stack spacing={4}>
        <HeadingButton
          title={board.title}
          button={
            <HStack>
              {hasCreateCollectionsPermission && (
                <Button variant="outline" onClick={openAccessModal}>
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              )}
              <Button
                as={Link}
                to={`/${slug}/board/${board.id}`}
                variant="outline"
              >
                {t('button.see_more')}
              </Button>
            </HStack>
          }
        />
        <Description>{board.description}</Description>
      </Stack>
      {board.collections.length > 0 && (
        <SimpleGrid columns={[1, null, 2]} spacing={6}>
          {board.collections.map(collection => (
            <CollectionListItem key={collection.id} collection={collection} />
          ))}
        </SimpleGrid>
      )}
      {board.metrics.collections === 0 && (
        <EmptyState
          title={t('common.nothing_here_yet')}
          description={`${t('board.theres_no_collections_on_this_board_yet')} ${
            hasCreateCollectionsPermission ? '' : t('common.check_back_later')
          }`}
          filename="tea.svg"
          button={
            hasCreateCollectionsPermission ? (
              <Button
                colorScheme="teal"
                onClick={() => onClickCreate(board)}
                width="full"
              >
                {t('common.create_collection')}
              </Button>
            ) : null
          }
        />
      )}
      <Modal isOpen={showAccessModal} onClose={closeAccessModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('board.visibility_of_the_board_title', { title: board.title })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BoardAccessText />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default BoardListItem;
